import {
  Suspense,
  createContext,
  lazy,
  useContext,
  useRef,
  useState,
} from "react";
import "./scss/style.scss";
import "@dvij-infotech/react-phone-input-2-country-sort/lib/style.css";
import "./language";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";

const AppContextInput = createContext();
export const useAppContextInput = () => useContext(AppContextInput);

const AppLayout = lazy(() => import(/* webpackChunkName: "layout" */ './layouts'));
const AppProvider = lazy(() => import(/* webpackChunkName: "provider" */ './provider'));
const AppRoutes = lazy(() => import(/* webpackChunkName: "routes" */ './routes'));

function App() {
  const inputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [plan, setPlan] = useState("trial");
  const handleInputFocus = (props) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPlan(props);
    if (window.screen.width > 767) {
      inputRef.current.focus();
    } else {
      mobileInputRef.current.focus();
    }
  };

  return (
    <Suspense fallback={<></>}>
      {/* Wrap each lazy-loaded component with Suspense */}
      <GoogleOAuthProvider clientId={google_client_id}>
        <AppProvider>
          <AppContextInput.Provider
            value={{
              inputRef,
              mobileInputRef,
              handleInputFocus,
              plan,
            }}
          >
            <Suspense fallback={<></>}>
              <AppLayout>
                {/* Wrap nested lazy-loaded components with Suspense */}
                <Suspense>
                  <AppRoutes />
                </Suspense>
              </AppLayout>
            </Suspense>
          </AppContextInput.Provider>
        </AppProvider>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
